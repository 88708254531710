import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useQuery } from 'react-query'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import history from '../../_helpers/history'
import { userService } from '../../_services'
import AbstractCollection from '../AbstractComponents/abstractCollection'
import DateTimeString from '../AbstractComponents/util/customRender/dateTimeString'

const Users = () => {
  const queryFn = userService.getAllUsers
  const collectionName = 'Users'
  const queryKey = collectionName.toLowerCase()
  const { enqueueSnackbar } = useSnackbar()
  // Queries
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, '-createdAt'),
    search: withDefault(StringParam, ''),
  })
  const { isLoading, isError, data, error } = useQuery(
    [queryKey, { page: query.page, sort: query.sort, search: query.search }],
    () => queryFn(query.page, query.sort, query.search)
  )
  if (isLoading) return <span className="spanText">Loading...</span>
  if (isError) {
    enqueueSnackbar(`Server Error: ${error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">Error: {error.response.data.message}</span>
    )
  }
  return (
    <AbstractCollection
      columns={[
        {
          title: 'Name',
          field: 'name',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Email',
          field: 'email',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Role',
          field: 'role',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Verified',
          field: 'verified',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Phone Number',
          field: 'phoneNumber',
          customFilterAndSearch: () => true,
        },
        // {
        //   title: 'Verified phone number',
        //   field: 'verifiedPhoneNumber',
        //   customFilterAndSearch: () => true,
        // },
        {
          title: 'City',
          field: 'city',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Creation Date',
          field: 'createdAt',
          render: DateTimeString(['createdAt']),
          customFilterAndSearch: () => true,
        },
      ]}
      mainActions={[]}
      actions={[
        {
          onClick: (event, row) => history.push(`/user/${row.email}`),
          icon: OpenInNewIcon,
        },
      ]}
      title={collectionName}
      isLoading={isLoading}
      isError={isError}
      documents={data.documents}
      pages={data.pages}
      error={error}
      query={query}
      setQuery={setQuery}
    />
  )
}
export default Users

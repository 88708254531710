import TextField from '@material-ui/core/TextField'
import React from 'react'

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(.){8,}$/
const input = {
  isValid: (element) => passwordRegex.test(element.value),
  isEmpty: (element) => element.value.length === 0,
  errorMessage: (label) =>
    `${label} should contain at least one digit, one lower case letter and at least 8 characters !`,
  render: (props, index) => (
    <TextField
      margin="dense"
      label={props.label + (props.required ? '*' : '')}
      type="password"
      inputProps={{
        autocomplete: 'new-password',
      }}
      fullWidth
      key={index}
      autoFocus={index === 0}
    />
  ),
  getValue: (element) => element.value,
}
export default input

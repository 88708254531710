import { API } from '../_helpers'

const route = 'items'
const getAll = async (page, sort, search) =>
  API.get(
    `v1/${route}?page=${page}&sort=${sort}${search ? `&search=${search}` : ''}`
  )
const getOne = async (slug) => API.get(`v1/${route}/${slug}`)
const createOne = async (body) => API.post(`v1/${route}`, body)
const editOne = async (slug, body) => API.patch(`v1/${route}/${slug}`, body)
const deleteOne = async (slug) => API.delete(`v1/${route}/${slug}`)
export default {
  getAll,
  getOne,
  createOne,
  editOne,
  deleteOne,
}

import { API } from '../_helpers'

const route = 'contactUs'
const getAll = async (page, sort, search) =>
  API.get(
    `v1/${route}?page=${page}&sort=${sort}${search ? `&search=${search}` : ''}`
  )
const getOne = async (id) => API.get(`v1/${route}/${id}`)
const editOne = async (id, body) => API.patch(`v1/${route}/${id}`, body)
const deleteOne = async (id) => API.delete(`v1/${route}/${id}`)
export default {
  getAll,
  getOne,
  editOne,
  deleteOne,
}

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import history from '../../_helpers/history'
import { authService } from '../../_services'
import Logo from '../../assets/images/logo-white.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: theme.spacing(75),
      height: theme.spacing(75),
      position: 'fixed',
      top: '50%',
      left: '50%',
      /* bring your own prefixes */
      transform: 'translate(-50%, -50%)',
      borderRadius: '3%',
    },
    backgroundColor: '#202126',
    height: '100vh',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '0px',
    boxShadow:
      '0 0px 8px 0 rgba(0, 0, 0, 0.1), 0 0px 20px 0 rgba(0, 0, 0, 0.1)',
    width: '60%',
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  tpd: {
    marginBottom: '5%',
    fontSize: '36px',
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: '10px',
    backgroundColor: '#000000',
    fontWeight: 'bold',
    width: '200px',
  },
}))
const Login = () => {
  const classes = useStyles()
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const login = () => {
    event.preventDefault()
    event.stopPropagation()
    authService
      .login({ email, password })
      .then(() => {
        queryClient.invalidateQueries()
        enqueueSnackbar('Logged In Successfully', {
          variant: 'success',
        })
        history.replace('/')
      })
      .catch((err) => {
        if (err?.response?.data?.message)
          enqueueSnackbar(`Login Failed: ${err?.response?.data?.message}`, {
            variant: 'error',
          })
      })
  }
  return (
    <div className={classes.root}>
      <Paper elevation={3}>
        <Grid container direction="column" justify="center" alignItems="center">
          <img src={Logo} className={clsx(classes.logo)} width="40%" alt="" />
          <Typography variant="h3" gutterBottom className={clsx(classes.tpd)}>
            Eden Solutions Admin Panel
          </Typography>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
            onSubmit={login}
          >
            <TextField
              value={email}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              className={clsx(classes.textField)}
              onChange={(event) => setEmail(event.target.value)}
              required
              error={email === ''}
              helperText={email === '' ? 'Field is required' : ''}
            />
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              autoComplete="current-password"
              className={clsx(classes.textField)}
              onChange={(event) => setPassword(event.target.value)}
              required
              error={password === ''}
              helperText={password === '' ? 'Field is required' : ''}
            />
            <Button
              size="large"
              variant="contained"
              color="secondary"
              className={classes.button}
              type="submit"
            >
              Login
            </Button>
          </form>
        </Grid>
      </Paper>
    </div>
  )
}
export default Login

import { serialize } from 'object-to-formdata'

const compare = (source, state) =>
  Object.keys(state).reduce((destination, key) => {
    if (key === 'media') return { ...destination, [key]: state[key] }
    if (typeof state[key] === 'object') {
      const comparedObject = compare(source[key], state[key])
      return {
        ...destination,
        ...(Object.keys(comparedObject).length && { [key]: comparedObject }),
      }
    }
    if (`${state[key]}` !== `${source[key]}` && `${state[key]}`.length > 0)
      destination[key] = state[key]
    return destination
  }, {})
const pick = (object, keys) =>
  keys.reduce((obj, key) => {
    if (
      object &&
      Object.prototype.hasOwnProperty.call(object, key) &&
      object[key]
    )
      obj[key] = object[key]
    return obj
  }, {})
const omit = (object, keys) => {
  if (!keys.length) return object
  const { [keys.pop()]: omitted, ...rest } = object
  return omit(rest, keys)
}
const getFormData = (object) => serialize(object)
const objectsHelper = {
  compare,
  getFormData,
  pick,
  omit,
}
export default objectsHelper

import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useQuery } from 'react-query'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import history from '../../_helpers/history'
import { appointmentService } from '../../_services'
import AbstractCollection from '../AbstractComponents/abstractCollection'
import DateString from '../AbstractComponents/util/customRender/dateString'
import DateTimeString from '../AbstractComponents/util/customRender/dateTimeString'
import NameString from '../AbstractComponents/util/customRender/nameString'

const Appointments = () => {
  const queryFn = appointmentService.getAll
  const collectionName = 'Appointment'
  const queryKey = collectionName.toLowerCase()
  const { enqueueSnackbar } = useSnackbar()
  // Queries
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, '-createdAt'),
    search: withDefault(StringParam, ''),
  })
  const { isLoading, isError, data, error } = useQuery(
    [queryKey, { page: query.page, sort: query.sort, search: query.search }],
    () => queryFn(query.page, query.sort, query.search)
  )
  if (isLoading) return <span className="spanText">Loading...</span>
  if (isError) {
    enqueueSnackbar(`Server Error: ${error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">Error: {error.response.data.message}</span>
    )
  }
  return (
    <AbstractCollection
      columns={[
        {
          title: 'Name',
          field: 'name',
          render: NameString(['name']),
          customFilterAndSearch: () => true,
        },
        {
          title: 'Reason',
          field: 'reason',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Message',
          field: 'message',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Contacted',
          field: 'contacted',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Date',
          field: 'date',
          render: DateString(['date']),
          customFilterAndSearch: () => true,
        },
        {
          title: 'Creation Date',
          field: 'createdAt',
          render: DateTimeString(['createdAt']),
          customFilterAndSearch: () => true,
        },
      ]}
      mainActions={[]}
      actions={[
        {
          onClick: (event, row) => history.push(`/appointment/${row._id}`),
          icon: OpenInNewIcon,
        },
      ]}
      title={collectionName}
      isLoading={isLoading}
      isError={isError}
      documents={data.documents}
      pages={data.pages}
      error={error}
      query={query}
      setQuery={setQuery}
    />
  )
}
export default Appointments

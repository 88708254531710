import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React from 'react'

const input = {
  isValid: () => true,
  isEmpty: () => false,
  errorMessage: (label) => `${label} is invalid`,
  render: (props, index) => (
    <FormControlLabel
      control={<Checkbox defaultChecked={props.value} color="primary" />}
      label={props.label + (props.required ? '*' : '')}
      labelPlacement="start"
      key={index}
      style={{ width: '100%', display: 'flex' }}
    />
  ),
  getValue: (element) => element.checked,
}
export default input

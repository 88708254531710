import TextField from '@material-ui/core/TextField'
import React from 'react'

const input = {
  isValid: (element) => !element.value || element.value > 0,
  isEmpty: (element) => element.value.length === 0,
  errorMessage: (label) => `${label} is invalid`,
  render: (props, index) => (
    <TextField
      margin="dense"
      label={props.label + (props.required ? '*' : '')}
      type="number"
      fullWidth
      key={index}
      autoFocus={index === 0}
      defaultValue={props.value}
    />
  ),
  getValue: (element) => element.value,
}
export default input

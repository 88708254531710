import './index.css'

import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import history from './_helpers/history'
import isTokenExpired from './_helpers/isTokenExpired'
import PrivateRoute from './_helpers/PrivateRoute'
import TokenUtils from './_helpers/tokenUtils'
import Layout from './components/Layout/Layout'
import LoginPage from './components/Login/Login'

const queryClient = new QueryClient()
const theme = createTheme({
  typography: {
    fontFamily: [
      '"Segoe UI"',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#1F4696',
      contrastText: '#fff',
    },
  },
})
ReactDOM.render(
  <Router history={history}>
    <QueryClientProvider client={queryClient}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route
                path="/"
                render={() =>
                  !isTokenExpired(TokenUtils.getAccessToken()) ? (
                    <PrivateRoute component={Layout} />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
            </Switch>
            <ReactQueryDevtools initialIsOpen={false} />
          </SnackbarProvider>
        </ThemeProvider>
      </QueryParamProvider>
    </QueryClientProvider>
  </Router>,
  document.getElementById('root')
)

/**
 * This file is used to show array as tables
 */ import MaterialTable from 'material-table'
import React from 'react'
import { useQueryParams } from 'use-query-params'

const ItemPaginate = (props) => {
  const [query, setQuery] = useQueryParams(props.queryParams)
  return (
    <MaterialTable
      title={props.title}
      data={props.data}
      columns={props.columns}
      options={{
        pageSize: props.data.length > 10 ? 10 : props.data.length,
        pageSizeOptions: [5, 10, 20, 30],
        debounceInterval: 1000,
        searchText: query.subsearch,
        tableLayout: 'auto',
        search: true,
        initialPage: query.subpage,
        actionsColumnIndex: props.columns.length,
      }}
      style={{
        overflowX: 'hidden',
        width: '100%',
      }}
      onChangePage={(page) =>
        setQuery({ subsearch: query.subsearch, subpage: page })
      }
      onSearchChange={(search) => setQuery({ subsearch: search, subpage: 1 })}
      actions={props.actions}
    />
  )
}
export default ItemPaginate

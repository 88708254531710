/**
 * This file contains the app routes
 *
 */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import Admins from '../collectionComponents/admins'
import Appointments from '../collectionComponents/appointments'
import ContactUs from '../collectionComponents/contactUs'
import Items from '../collectionComponents/items'
import Orders from '../collectionComponents/orders'
import Users from '../collectionComponents/users'
import RoleBasedGuard from '../Guards/RoleBasedGuard'
import Admin from '../singleComponents/admin'
import Appointment from '../singleComponents/appointment'
import ContactUsSingle from '../singleComponents/contactUsSingle'
import Item from '../singleComponents/item'
import Order from '../singleComponents/order'
import Profile from '../singleComponents/profile'
import User from '../singleComponents/user'

const MainRoutes = () => {
  return (
    <>
      <Route exact path="/" render={() => <Redirect to="/orders" />} />{' '}
      <Route path="/orders" component={Orders} />
      <Route path="/order/:id" component={Order} />{' '}
      <Route path="/items" component={Items} />
      <Route path="/item/:slug" component={Item} />{' '}
      <Route path="/appointments" component={Appointments} />
      <Route path="/appointment/:id" component={Appointment} />{' '}
      <Route path="/contact-us-requests" component={ContactUs} />
      <Route path="/contact-us-request/:id" component={ContactUsSingle} />{' '}
      <Route path="/profile/:id" component={Profile} />{' '}
      <RoleBasedGuard permitedRoles={['admin', 'owner']}>
        <Route path="/admins" component={Admins} />
      </RoleBasedGuard>{' '}
      <RoleBasedGuard permitedRoles={['admin', 'owner']}>
        <Route path="/admin/:id" component={Admin} />
      </RoleBasedGuard>{' '}
      <RoleBasedGuard permitedRoles={['owner']}>
        <Route path="/users" component={Users} />
      </RoleBasedGuard>{' '}
      <RoleBasedGuard permitedRoles={['owner']}>
        <Route path="/user/:id" component={User} />
      </RoleBasedGuard>
    </>
  )
}
export default MainRoutes

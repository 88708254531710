import { API } from '../_helpers'

const route = 'users'
const addOne = async (body) => API.post(`v1/${route}/`, body)
const getAll = async (page, sort, search, role) =>
  API.get(
    `v1/${route}?page=${page}&sort=${sort}${search ? `&search=${search}` : ''}${
      role ? `&role=${role}` : ''
    }`
  )
const getAllUsers = async (page, sort, search) =>
  getAll(page, sort, search, 'user')
const getOne = async (email) => API.get(`v1/${route}/${email}`)
const deleteOne = async (email) => API.delete(`v1/${route}/${email}`)
const editOne = async (email, body) => API.patch(`v1/${route}/${email}`, body)
export default {
  getAll,
  getAllUsers,
  addOne,
  getOne,
  editOne,
  deleteOne,
}

const accessTokenName = 'a_token'
const refreshTokenName = 'r_token'
export const getAccessToken = () => localStorage.getItem(accessTokenName)
export const getRefreshToken = () => localStorage.getItem(refreshTokenName)
export const setAccessToken = async (newAccessToken) =>
  localStorage.setItem(accessTokenName, newAccessToken)
export const setRefreshToken = async (newRrefreshToken) =>
  localStorage.setItem(refreshTokenName, newRrefreshToken)
export const removeAccessToken = async () =>
  localStorage.removeItem(accessTokenName)
export const removeRefreshToken = async () =>
  localStorage.removeItem(refreshTokenName)
const TokenUtils = {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeAccessToken,
  removeRefreshToken,
}
export default TokenUtils

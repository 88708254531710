/**
 * This file is implemented to automate single component
 *
 */
import Button from '@material-ui/core/Button'
import moment from 'moment'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import { NumberParam, StringParam } from 'use-query-params'

import AbstractDialog from './abstractDialog'
import ItemPaginate from './addOns/itemPaginate'
import DateTimeString from './util/customRender/dateTimeString'
import ExcerptDisplay from './util/customRender/excerpt'
import PriceDisplay from './util/customRender/priceString'

const AbstractSingle = (props) => {
  const [open, setOpen] = useState(false)
  const [inputs, setInputs] = useState([])
  const [title, setTitle] = useState('')
  const [submitFunction, setSubmitFunction] = useState(() => {})
  const [submitInfo, setSubmitInfo] = useState('')
  const [noSubmitInfo, setNoSubmitInfo] = useState(false)
  const [noSubmitButton, setNoSubmitButton] = useState(false)
  const [confirmButton, setConfirmButton] = useState(false)
  const checkIsToBeShow = (name) => {
    name = name.toLowerCase()
    if (
      !name.endsWith('id') &&
      !name.endsWith('slug') &&
      name !== 'wishlist' &&
      name !== 'tabledata' &&
      name !== 'password' &&
      name !== 'cart' &&
      !props.notToShow?.includes(name)
    )
      return true
    return false
  }
  const checkName = (name) =>
    name.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
  const formatDate = (date) => moment(date).format('DD/MM/YYYY')
  const formatDateTime = (date) => moment(date).format('DD/MM/YYYY hh:mm A')
  const getType = (data) =>
    typeof data === 'object'
      ? Array.isArray(data)
        ? 'array'
        : 'object'
      : 'nonObject'
  const flattenData = (object, newObject = {}) => {
    Object.keys(object).forEach((key) => {
      if (
        object[key] &&
        !Array.isArray(object[key]) &&
        typeof object[key] === 'object'
      )
        flattenData(object[key], newObject)
      else newObject[key] = object[key]
    })
    return newObject
  }
  const dataToDisplay = () => {
    const { data } = props
    const nonObjects = []
    const objects = []
    const arrays = []
    Object.keys(data).forEach((el) => {
      if (data[el] === null || data[el] === undefined) return
      if (el === 'name' && getType(data[el]) === 'array')
        data[el] = data[el].join(' ')
      const type = getType(data[el])
      if (type === 'nonObject') nonObjects.push(el)
      if (type === 'object' && Object.keys(data[el]).length) objects.push(el)
      if (type === 'array' && data[el].length) arrays.push(el)
    })
    return (
      <>
        {nonObjects.length ? (
          <>
            <div className="fullWidth center">
              <h2>Main Info</h2>
            </div>
            <div className="singleContainer fullWidth">
              {nonObjects.map((el) => getDataToShow(el, data[el]))}
            </div>
          </>
        ) : (
          <></>
        )}
        {objects.length ? (
          <div className="fullWidth">
            {objects.map((el) => getDataToShow(el, data[el]))}
          </div>
        ) : (
          <></>
        )}
        {arrays.length ? (
          <div className="fullWidth">
            {arrays.map((el) => getDataToShow(el, data[el]))}
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }
  const getDataToShow = (name, data, addClass) => {
    if (!checkIsToBeShow(name)) return <></>
    if (data === null || data === undefined) return <></>
    const type = getType(data)
    switch (type) {
      case 'object':
        if (!Object.keys(data).length) return <></>
        return (
          <>
            <div className="center">
              <h2>
                <strong>{`${checkName(name)}`}</strong>
              </h2>
            </div>
            <div className="singleContainer">
              {Object.keys(data).length > 1
                ? Object.keys(data).map((el) => getDataToShow(el, data[el]))
                : Object.keys(data).map((el) =>
                    getDataToShow(el, data[el], 'fullWidth')
                  )}
            </div>
          </>
        )
      case 'array':
        if (!data.length) return <></>
        if (typeof data[0] === 'object') {
          data = data.map((el) => flattenData(el))
          const columns = []
          Object.keys(data[0]).forEach((el) => {
            if (checkIsToBeShow(el)) {
              if (el === 'media' || el === 'variants') return
              if (
                el === 'price' ||
                el === 'salePrice' ||
                el === 'total' ||
                el === 'giftCard' ||
                el === 'shipping' ||
                el === 'amount'
              )
                return columns.push({
                  title: checkName(el),
                  field: el,
                  render: PriceDisplay(el),
                })
              if (el === 'description')
                return columns.push({
                  title: checkName(el),
                  field: el,
                  render: ExcerptDisplay(el),
                })
              if (
                el === 'createdAt' ||
                el === 'updatedAt' ||
                el === 'datePublished'
              )
                return columns.push({
                  title: checkName(el),
                  field: el,
                  render: DateTimeString(el),
                })
              data[0][el] = `${data[0][el]
                .toString()
                .charAt(0)
                .toUpperCase()}${data[0][el].toString().substring(1)}`
              return columns.push({ title: checkName(el), field: el })
            }
          })
          return (
            <>
              <div className="fullWidth center">
                <h2>
                  <strong>{`${checkName(name)}`}</strong>
                </h2>
              </div>
              <ItemPaginate
                queryParams={{
                  subpage: NumberParam,
                  subsearch: StringParam,
                }}
                actions={props.actions}
                title={checkName(name)}
                data={data}
                columns={columns}
              />
            </>
          )
        }
        return (
          <>
            <div className="fullWidth center">
              <h2>
                <strong>{`${checkName(name)}`}</strong>
              </h2>
            </div>
            <div className="singleContainer fullWidth">
              {data.map((el) =>
                name === 'media' ? (
                  <img
                    className="media"
                    src={`${process.env.REACT_APP_MEDIA_SERVER}${el}`}
                    height="150"
                    width="200"
                    alt=""
                  />
                ) : (
                  <div className="singleObj fullWidth">
                    <h3 style={{ textTransform: 'capitalize' }}>{el}</h3>
                  </div>
                )
              )}
            </div>
          </>
        )
      default:
        // eslint-disable-next-line no-case-declarations
        let content = null
        switch (name) {
          case 'media':
            content = (
              <img
                src={`${process.env.REACT_APP_MEDIA_SERVER}${data}`}
                height="150"
                width="200"
                alt=""
              />
            )
            break
          case 'birthdate':
          case 'expiryDate':
            content = <p>{formatDate(data)}</p>
            break
          case 'dateTime':
          case 'createdAt':
            content = <p>{formatDateTime(data)}</p>
            break
          case 'price':
          case 'salePrice':
          case 'total':
          case 'giftCard':
          case 'shipping':
          case 'amount':
            content = (
              <p>
                EGP{' '}
                <NumberFormat
                  value={data}
                  displayType="text"
                  thousandSeparator
                />
              </p>
            )
            break
          default:
            content = (
              <p>{`${data.toString().charAt(0).toUpperCase()}${data
                .toString()
                .substring(1)}`}</p>
            )
        }
        return (
          <div className={`singleData${addClass ? ` ${addClass}` : ''}`}>
            <p>
              <strong>{`${checkName(name)}`}</strong>
            </p>
            {content}
          </div>
        )
    }
  }
  return (
    <div className="singleComponent">
      {open ? (
        <AbstractDialog
          open={open}
          close={() => {
            setOpen(false)
          }}
          title={title}
          inputs={inputs}
          info={submitInfo}
          noSubmitInfo={noSubmitInfo}
          noSubmitBtn={noSubmitButton}
          confirm={confirmButton}
          onSubmit={(args) => {
            if (typeof args === 'object') {
              setOpen(false)
              submitFunction(args)
            }
          }}
        />
      ) : (
        ''
      )}
      <div className="btnActions">
        {props.updateOption ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true)
              setTitle(`Edit ${props.collectionName.toLowerCase()}`)
              setInputs(props.updateForm)
              setSubmitFunction(() => props.updateFunction)
              setSubmitInfo(``)
              setNoSubmitInfo(true)
              setNoSubmitButton(false)
              setConfirmButton(false)
            }}
          >
            Update {props.collectionName}
          </Button>
        ) : (
          ''
        )}
        {props.specificationsOption ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true)
              setTitle('Edit Specifications')
              setInputs(props.specificationsForm)
              setSubmitFunction(() => props.specificationsFunction)
              setSubmitInfo(``)
              setNoSubmitInfo(true)
              setNoSubmitButton(false)
              setConfirmButton(false)
            }}
          >
            Update Specifications
          </Button>
        ) : (
          ''
        )}
        {props.addVariant ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true)
              setTitle(`Add variant of ${props.collectionName.toLowerCase()}`)
              setInputs(props.addVariantForm)
              setSubmitFunction(() => props.addVariantFunction)
              setSubmitInfo(``)
              setNoSubmitInfo(true)
              setNoSubmitButton(false)
              setConfirmButton(false)
            }}
          >
            Add variant {props.collectionName}
          </Button>
        ) : (
          ''
        )}
        {props.deleteOption ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(true)
              setTitle(`Delete ${props.collectionName.toLowerCase()}`)
              setInputs([])
              setSubmitFunction(() => props.deleteFunction)
              setSubmitInfo(
                `Are you sure you want to delete this ${props.collectionName.toLowerCase()}?`
              )
              setNoSubmitInfo(false)
              setNoSubmitButton(true)
              setConfirmButton(true)
            }}
          >
            Delete {props.collectionName}
          </Button>
        ) : (
          ''
        )}
      </div>
      {dataToDisplay()}
    </div>
  )
}
export default AbstractSingle

import React from 'react'
import NumberFormat from 'react-number-format'

const PriceString = (path) => (rowData) => {
  if (rowData[path])
    return (
      <p>
        EGP{' '}
        <NumberFormat
          value={rowData[path]}
          displayType="text"
          thousandSeparator
        />
      </p>
    )
  return <p />
}
export default PriceString

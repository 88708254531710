import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Pagination from '@material-ui/lab/Pagination'
import MaterialTable from 'material-table'
import { useSnackbar } from 'notistack'
import React from 'react'

import ActionButton from './actionButton'

const AbstractCollection = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  // initializing query params
  const handleSort = (event) => {
    const oldSort = props.query.sort
    const oldOrder = oldSort[0]
    props.setQuery({ ...props.query, sort: oldOrder + event.target.value })
  }
  const handleSortOrder = (event) => {
    const oldSort = props.query.sort
    const oldWithoutOrder = oldSort.slice(1)
    props.setQuery({
      ...props.query,
      sort: event.target.value + oldWithoutOrder,
    })
  }
  if (props.isLoading) return <span className="spanText">Loading...</span>
  if (props.isError) {
    enqueueSnackbar(`Server Error: ${props.error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">
        Error: {props.error.response.data.message}
      </span>
    )
  }
  return (
    <>
      {/* Sort All Data By Columns */}
      <div className="collectionActions">
        <FormControl className="formControl">
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Sort by:
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={props.query.sort.slice(1)}
            onChange={handleSort}
            displayEmpty
            className="selectEmpty"
          >
            <MenuItem value="createdAt">Default</MenuItem>
            {props.columns.map((column) => (
              <MenuItem key={column.field + column.title} value={column.field}>
                {column.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Select a column to sort the whole data by
          </FormHelperText>
        </FormControl>
        <FormControl className="formControl">
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Order:
          </InputLabel>
          <Select
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            value={props.query.sort[0]}
            onChange={handleSortOrder}
            displayEmpty
            className="selectEmpty"
          >
            <MenuItem value="+">
              <em>Ascending</em>
            </MenuItem>
            <MenuItem value="-">
              <em>Descending</em>
            </MenuItem>
          </Select>
          <FormHelperText>
            Select Order to sort the selected column by
          </FormHelperText>
        </FormControl>
        {/* End Sort By Columns */}
        <div className="btnActions">
          {props.mainActions.map((action) => (
            <ActionButton
              key={action.buttonLabel}
              buttonLabel={action.buttonLabel}
              buttonColor={action.buttonColor}
              withForm={action.withForm}
              form={action.form}
              onSubmit={(args) => action.onSubmit(args)}
            />
          ))}
        </div>
      </div>
      <div className={props.documents.length ? 'tableComponent' : 'tableEmpty'}>
        <MaterialTable
          title={props.title}
          isLoading={props.isLoading}
          data={props.documents}
          columns={props.columns}
          options={{
            pageSize: 10,
            debounceInterval: 1000,
            actionsColumnIndex: props.columns.length,
            searchText: props.query.search,
            search: true,
            tableLayout: 'auto',
          }}
          onSearchChange={(search) =>
            props.setQuery({ ...props.query, search, page: 1 })
          }
          actions={props.actions}
          components={{
            Pagination: () => (
              <>
                <Pagination
                  page={props.query.page}
                  count={props.pages}
                  color="primary"
                  showFirstButton
                  showLastButton
                  onChange={(event, value) =>
                    props.setQuery({ ...props.query, page: value })
                  }
                  className="customPagination"
                />
              </>
            ),
          }}
        />
      </div>
    </>
  )
}
export default AbstractCollection

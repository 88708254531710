/**
 * Display content only if user has admin role
 */
import React from 'react'

import { accountProperties } from '../../_helpers'
import Guard from './guard'

const RoleBasedGuard = (props) => {
  return (
    <Guard condition={props.permitedRoles.includes(accountProperties().role)}>
      {props.children}
    </Guard>
  )
}
export default RoleBasedGuard

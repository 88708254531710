import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import {
  brandsFields,
  categoriesFields,
  specificationsFields,
  variantsFields,
} from '../../_helpers/constants'
import history from '../../_helpers/history'
import { itemService } from '../../_services'
import AbstractSingle from '../AbstractComponents/abstractSingle'
import ObjectHelper from '../AbstractComponents/util/objectsHelper'

const Item = () => {
  const queryFn = itemService.getOne
  const addFn = itemService.createOne
  const updateFn = itemService.editOne
  const deleteFn = itemService.deleteOne
  const collectionName = 'Items'
  const singleCollectionName = 'Item'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const { slug } = params
  const { enqueueSnackbar } = useSnackbar()
  // Access the client
  const queryClient = useQueryClient()
  // Queries
  const { isLoading, isError, data, error } = useQuery(
    [singleQueryKey, { slug }],
    () => queryFn(slug)
  )
  // Mutations
  const updateMutation = useMutation((Data) => updateFn(slug, Data), {
    onError: (err) => {
      // An error happened!
      enqueueSnackbar(
        `${singleCollectionName} could not be edited: ${err.response.data.message}`,
        {
          variant: 'error',
        }
      )
    },
    onSuccess: (newData) => {
      // Invalidate and refetch
      queryClient.invalidateQueries(queryKey)
      queryClient.invalidateQueries(singleQueryKey, slug)
      history.replace(`/item/${newData.slug}`)
      enqueueSnackbar(`${singleCollectionName} edited successfully`, {
        variant: 'success',
      })
    },
  })
  const addVariantMutation = useMutation((newData) => addFn(newData), {
    onError: (err) => {
      // An error happened!
      enqueueSnackbar(
        `${singleCollectionName} variant could not be added: ${err.response.data.message}`,
        {
          variant: 'error',
        }
      )
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(queryKey)
      queryClient.invalidateQueries(singleQueryKey, slug)
      enqueueSnackbar(`${singleCollectionName} variant added successfully`, {
        variant: 'success',
      })
    },
  })
  const deleteMutation = useMutation(() => deleteFn(slug), {
    onError: (err) => {
      // An error happened!
      enqueueSnackbar(
        `${singleCollectionName} could not be deleted: ${err.response.data.message}`,
        {
          variant: 'error',
        }
      )
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(queryKey)
      queryClient.invalidateQueries(singleQueryKey, slug)
      enqueueSnackbar(`${singleCollectionName} deleted successfully`, {
        variant: 'success',
      })
      history.replace(`/items`)
    },
  })
  const handleUpdate = (args) => {
    const body = ObjectHelper.compare(data, args)
    const { media } = body
    delete body.media
    const formData = ObjectHelper.getFormData(body)
    if (media && media.length)
      Object.keys(media).forEach((key) => formData.append('media', media[key]))
    if (Object.keys(body).length || (media && media.length))
      updateMutation.mutate(formData)
  }
  const handleSpecifications = (args) => {
    const body = ObjectHelper.compare(
      data.specifications ? data.specifications : {},
      args
    )
    if (Object.keys(body).length)
      updateMutation.mutate(ObjectHelper.getFormData({ specifications: body }))
  }
  const addVariant = (args) => {
    const { media } = args
    delete args.media
    const formData = ObjectHelper.getFormData({
      ...args,
      name: data.name,
      category: data.category,
      brand: data.brand,
      parentSlug: slug,
      variant: true,
    })
    Object.keys(media).forEach((key) => formData.append('media', media[key]))
    addVariantMutation.mutate(formData)
  }
  const handleDelete = () => deleteMutation.mutate()
  if (isLoading) return <span className="spanText">Loading...</span>
  if (isError) {
    enqueueSnackbar(`Server Error: ${error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">Error: {error.response.data.message}</span>
    )
  }
  const updateForm = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      value: data.name,
      required: false,
    },
    {
      name: 'category',
      label: 'Category',
      type: 'select',
      options: categoriesFields,
      value: data.category,
      required: false,
    },
    {
      name: 'brand',
      label: 'Brand',
      type: 'select',
      options: brandsFields,
      value: data.brand,
      required: false,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textArea',
      value: data.description,
      required: false,
    },
    {
      name: 'price',
      label: 'Price',
      type: 'number',
      value: data.price,
      required: false,
    },
    {
      name: 'salePrice',
      label: 'Sale Price',
      type: 'number',
      value: data.salePrice,
      required: false,
    },
    {
      name: 'featured',
      label: 'Featured',
      type: 'boolean',
      value: data.featured,
      required: false,
    },
    {
      name: 'onSale',
      label: 'On Sale',
      type: 'boolean',
      value: data.onSale,
      required: false,
    },
    {
      name: 'outOfStock',
      label: 'Out Of Stock',
      type: 'boolean',
      value: data.outOfStock,
      required: false,
    },
    {
      name: 'media',
      label: 'Media',
      type: 'image',
      required: false,
    },
    ...variantsFields(data),
  ]
  const addForm = [
    {
      name: 'price',
      label: 'Price',
      type: 'number',
      value: data.price,
      required: true,
    },
    {
      name: 'salePrice',
      label: 'Sale Price',
      type: 'number',
      value: data.salePrice,
      required: false,
    },
    {
      name: 'onSale',
      label: 'On Sale',
      type: 'boolean',
      value: data.onSale,
      required: false,
    },
    {
      name: 'outOfStock',
      label: 'Out Of Stock',
      type: 'boolean',
      value: data.outOfStock,
      required: false,
    },
    {
      name: 'media',
      label: 'Media',
      type: 'image',
      required: true,
    },
    ...variantsFields(data),
  ]
  return (
    <AbstractSingle
      actions={[
        {
          onClick: (event, row) => history.push(`/item/${row.slug}`),
          icon: OpenInNewIcon,
        },
      ]}
      data={data}
      collectionName={singleCollectionName}
      updateOption
      updateForm={updateForm}
      updateFunction={(args) => handleUpdate(args)}
      addVariant={!data.variant}
      addVariantForm={addForm}
      addVariantFunction={(args) => addVariant(args)}
      specificationsOption={!data.variant}
      specificationsForm={specificationsFields(data)}
      specificationsFunction={(args) => handleSpecifications(args)}
      deleteOption
      deleteFunction={() => handleDelete()}
    />
  )
}
export default Item

import { Tooltip } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import MenuIcon from '@material-ui/icons/Menu'
import PeopleIcon from '@material-ui/icons/People'
import StorefrontIcon from '@material-ui/icons/Storefront'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Link, useLocation, withRouter } from 'react-router-dom'

import history from '../../_helpers/history'
import { authService } from '../../_services'
import Logo from '../../assets/images/logo-white.png'
import RoleBasedGuard from '../Guards/RoleBasedGuard'
import MainRoutes from '../Routes/MainRoutes'

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'grey',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'white',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: 'white',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: 'white',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: '#0D0E12',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: 'hidden',
    minHeight: '100vh',
  },
}))
const Layout = () => {
  const location = useLocation()
  const queryClient = useQueryClient()
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(true)
  const [activeName, setActiveName] = useState(null)
  const openDrawer = () => {
    setOpen(true)
  }
  const closeDrawer = () => {
    setOpen(false)
  }
  React.useEffect(() => {
    setActiveName(null)
  }, [window.location.href])
  const handleLogout = () => {
    queryClient.invalidateQueries()
    authService.logout()
    history.push('/login')
  }
  /**
   * Find icons here:
   * https://material-ui.com/components/material-icons/
   */ const drawerItems = [
    {
      name: 'Orders',
      route: '/orders',
      icon: <LocalShippingIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Items',
      route: '/items',
      icon: <StorefrontIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Appointments',
      route: '/appointments',
      icon: <EmojiPeopleIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Contact us requests',
      route: '/contact-us-requests',
      icon: <ContactPhoneIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Users',
      route: '/users',
      icon: <PeopleIcon />,
      roles: ['owner'],
    },
    {
      name: 'Admins',
      route: '/admins',
      icon: <SupervisorAccountIcon />,
      roles: ['owner', 'admin'],
    },
  ].map((item, i) => (
    <RoleBasedGuard key={i} permitedRoles={item.roles}>
      <ListItem
        button
        key={item.name}
        className={
          activeName === item.name ||
          location.pathname.indexOf(item.route.slice(0, -1)) !== -1
            ? 'activeMenu'
            : ''
        }
        onClick={() => {
          history.push(item.route)
          setActiveName(item.name)
        }}
      >
        <Tooltip title={item.name} placement="right">
          <ListItemIcon style={{ color: '#000000' }}>{item.icon}</ListItemIcon>
        </Tooltip>
        <ListItemText primary={item.name} style={{ color: '#000000' }} />
      </ListItem>
      <Divider key={i} style={{ backgroundColor: 'rgba(112,112,112,0.3)' }} />
    </RoleBasedGuard>
  ))
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className="spread menu">
            <img
              src={Logo}
              className={clsx(classes.logo)}
              width="40px"
              alt=""
            />
            <Typography color="textPrimary" variant="h6" noWrap>
              Eden Solutions Admin Panel
            </Typography>
            <div className="actions">
              <Link to="/profile/owner@edensolutions.tech">
                <AccountCircleIcon />
              </Link>
              <Link onClick={handleLogout} to="/">
                <ExitToAppIcon />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{drawerItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <MainRoutes />
      </main>
    </div>
  )
}
export default withRouter(Layout)

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import React from 'react'

const input = {
  isValid: () => true,
  isEmpty: () => false,
  errorMessage: (label) => `${label} is invalid`,
  render: (props) => (
    <FormControl style={{ marginTop: '16px' }} fullWidth>
      <InputLabel htmlFor="uncontrolled-native">
        {props.label + (props.required ? '*' : '')}
      </InputLabel>
      <NativeSelect
        fullWidth
        defaultValue={props.value || props.options[0].value}
        inputProps={{
          name: 'name',
          id: 'uncontrolled-native',
        }}
      >
        {props.options.map((option) => (
          <option value={option.value} disabled={option.disabled}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  ),
  getValue: (element) => element.value,
}
export default input

import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useQuery } from 'react-query'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import history from '../../_helpers/history'
import { ordersService } from '../../_services'
import AbstractCollection from '../AbstractComponents/abstractCollection'
import DateTimeString from '../AbstractComponents/util/customRender/dateTimeString'
import NameString from '../AbstractComponents/util/customRender/nameString'

const Orders = () => {
  const queryFn = ordersService.getAll
  const collectionName = 'Orders'
  const queryKey = collectionName.toLowerCase()
  const { enqueueSnackbar } = useSnackbar()
  // Queries
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, '-createdAt'),
    search: withDefault(StringParam, ''),
  })
  const { isLoading, isError, data, error } = useQuery(
    [queryKey, { page: query.page, sort: query.sort, search: query.search }],
    () => queryFn(query.page, query.sort, query.search)
  )
  if (isLoading) return <span className="spanText">Loading...</span>
  if (isError) {
    enqueueSnackbar(`Server Error: ${error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">Error: {error.response.data.message}</span>
    )
  }
  return (
    <AbstractCollection
      columns={[
        {
          title: 'Name',
          field: 'name',
          render: NameString(['name']),
          customFilterAndSearch: () => true,
        },
        {
          title: 'City',
          field: 'city',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Subtotal',
          field: 'subtotal',
          type: 'currency',
          currencySetting: {
            currencyCode: 'EGP',
          },
          customFilterAndSearch: () => true,
        },
        {
          title: 'Shipping',
          field: 'shipping',
          type: 'currency',
          currencySetting: {
            currencyCode: 'EGP',
          },
          customFilterAndSearch: () => true,
        },
        {
          title: 'Gift Card',
          field: 'giftCard',
          type: 'currency',
          currencySetting: {
            currencyCode: 'EGP',
          },
          customFilterAndSearch: () => true,
        },
        {
          title: 'Total',
          field: 'total',
          type: 'currency',
          currencySetting: {
            currencyCode: 'EGP',
          },
          customFilterAndSearch: () => true,
        },
        {
          title: 'Status',
          field: 'status',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Creation Date',
          field: 'createdAt',
          render: DateTimeString(['createdAt']),
          customFilterAndSearch: () => true,
        },
      ]}
      mainActions={[]}
      actions={[
        {
          onClick: (event, row) => history.push(`/order/${row._id}`),
          icon: OpenInNewIcon,
        },
      ]}
      title={collectionName}
      isLoading={isLoading}
      isError={isError}
      documents={data.documents}
      pages={data.pages}
      error={error}
      query={query}
      setQuery={setQuery}
    />
  )
}
export default Orders

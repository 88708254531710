const categories = [
  'Accessories',
  'Art & Design',
  'Automation',
  'Car Accessories',
  'Computers',
  'Health',
  'Home Automation',
  'Lighting',
  'Locks',
  'Mechanisms',
  'Robots',
  'Security & Surveillance',
  'Sensors',
  'Smart Wearables',
  'Smartphones',
  'Sound & Music',
  'Storage',
  'Tablets',
  'Watch',
  'Watch Accessories',
]
const brands = [
  'Aeotec',
  'Apple',
  'Arlo',
  'Arturia',
  'Audio Technica',
  'August',
  'Aukey',
  'Avoda',
  'B&O',
  'Beats',
  'Belkin',
  'Black Magic Design',
  'Bose',
  'COTEetCI',
  'Cisco',
  'Colgate',
  'DoorTec',
  'Dream Screen',
  'Evehome',
  'Fibaro',
  'Google Nest',
  'Hum by Colgate',
  'IK Multimedia',
  'Initio',
  'JBL',
  'Kingston',
  'LG',
  'LaCie',
  'LinkSys',
  'Lock Master',
  'Logitech',
  'Manfrotto',
  'Marshall',
  'Phillips Hue',
  'Pioneer',
  'Pivo',
  'Porodo',
  'Prodigee',
  'Ravpower',
  'Ring',
  'Samsung',
  'Seagate',
  'Sennhiser',
  'Sensibo',
  'Shelly',
  'Shure',
  'Skoog',
  'Solace Controls',
  'Somfy',
  'Sonos',
  'Soundbrenner',
  'Specdrums',
  'Sphero',
  'Spire',
  'Transcend',
  'Universal Audio',
  'UpFuse',
  'Upright Go',
  'V2',
  'Viva Madrid',
  'Wacom',
  'Western Digital',
  'X-doria',
  'YourCuttle',
  'iRobot',
]
const variants = [
  'bandColor',
  'bandSize',
  'bandType',
  'caseColor',
  'caseMaterial',
  'color',
  'displaySize',
  'graphicsCard',
  'kitType',
  'memory',
  'pack',
  'processor',
  'size',
  'storage',
]
const specifications = [
  'accessibility',
  'accessories',
  'additionalDetails',
  'adjustableBassAndTrebleControls',
  'appleAirPlay',
  'apps',
  'audio',
  'audioFormats',
  'audioSource',
  'audioSpecifications',
  'audioTechnology',
  'automation',
  'battery',
  'batteryLife',
  'batteryPlaytime',
  'batteryType',
  'bluetooth',
  'cabinetPrincipal',
  'camera',
  'caseColor',
  'caseMaterial',
  'charging',
  'chargingCase',
  'chargingTime',
  'chip',
  'color',
  'colorTemperature',
  'colorways',
  'compatibility',
  'compatibleApp',
  'connectivity',
  'controlKnob',
  'controls',
  'customizableSoundEq',
  'design',
  'diameter',
  'dimensions',
  'display',
  'environmentalConditions',
  'ethernetPort',
  'features',
  'filterLife',
  'filters',
  'fit',
  'fitting',
  'graphics',
  'hardwareFeatures',
  'hdmiPort',
  'headphoneType',
  'homeKitCompatible',
  'hueBridgeCompatibility',
  'length',
  'lensFieldOfView',
  'lightModes',
  'lightOutput',
  'lumenOutput',
  'material',
  'maxVideoResolution',
  'memory',
  'microphone',
  'modelNumber',
  'motionDetection',
  'mountingOptions',
  'nightVisionColor',
  'noiseIsolation',
  'operatingDistance',
  'overview',
  'pack',
  'photoFormats',
  'playTime',
  'pollutants',
  'ports',
  'powerAndBattery',
  'processor',
  'roomSizeCoverage',
  'screen',
  'sensitivity',
  'sensors',
  'shape',
  'simCard',
  'siren',
  'siriRemote',
  'size',
  'softwareFeatures',
  'sound',
  'soundCodecs',
  'spotlight',
  'stereoMono',
  'stereoPairing',
  'storage',
  'streamingServices',
  'supportedAcsAndHeatPumps',
  'systemRequirement',
  'technology',
  'topPanelControls',
  'touchSurface',
  'videoFeatures',
  'videoFormats',
  'videoModes',
  'waterResistance',
  'waterproofRating',
  'weight',
  'whatIsInTheBox',
  'width',
  'wifi',
  'wiredConnectivity',
  'wirelessConnectivity',
]
const changeCamelCase = (word) => {
  const result = word.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const categoriesFields = categories.map((category) => ({
  label: category,
  name: category,
}))
export const brandsFields = brands.map((brand) => ({
  label: brand,
  name: brand,
}))
export const variantsFields = (data = {}) =>
  variants.map((variant) => ({
    name: variant,
    label: changeCamelCase(variant),
    type: 'text',
    value: data[variant],
    required: !!data[variant],
  }))
export const specificationsFields = (data = {}) =>
  specifications.map((specification) => ({
    name: specification,
    label: changeCamelCase(specification),
    type: 'textArea',
    value: data.specifications ? data.specifications[specification] : null,
    required: false,
  }))

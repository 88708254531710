import React from 'react'
import { Route } from 'react-router-dom'

import Login from '../components/Login/Login'
import isTokenExpired from './isTokenExpired'
import TokenUtils from './tokenUtils'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const token = TokenUtils.getAccessToken()
        if (!token || isTokenExpired(token)) {
          // not logged in so redirect to login page with the return url
          return <Login from={props.location} />
        }
        // logged in so return component
        return <Component {...props} />
      }}
    />
  )
}
export default PrivateRoute

import axios from 'axios'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

import { authService } from '../_services'
import history from './history'
import TokenUtils from './tokenUtils'

const axiosRetry = require('axios-retry')

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  timeout: 15000,
  headers: {
    common: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
})
axiosInstance.interceptors.request.use(
  async (config) => {
    const { url } = config
    // Put Token in header in all routes except login
    if (url !== 'v1/auth/login')
      config.headers = {
        Authorization: `Bearer ${TokenUtils.getAccessToken()}`,
      }
    // Checking to refresh token if almost expired in all routes except the login and refresh routes
    if (url !== 'v1/auth/login' && url !== 'v1/auth/refresh-tokens') {
      const accessToken = TokenUtils.getAccessToken()
      if (accessToken) {
        const decodedJwt = jwtDecode(accessToken)
        const almostNow = moment().add(5, 'minutes').valueOf() / 1000
        if (!decodedJwt?.exp) {
          TokenUtils.removeAccessToken()
          TokenUtils.removeRefreshToken()
          history.push('/login')
        }
        if (decodedJwt.exp && decodedJwt.exp <= almostNow) {
          // Token almost expired, refresh
          const refreshToken = TokenUtils.getRefreshToken()
          try {
            const newToken = await authService.refreshToken({ refreshToken })
            TokenUtils.setAccessToken(newToken.access.token)
            TokenUtils.setRefreshToken(newToken.refresh.token)
            config.headers = {
              Authorization: `Bearer ${newToken.access.token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            }
          } catch (error) {
            // Redirect to login
            TokenUtils.removeAccessToken()
            TokenUtils.removeRefreshToken()
            history.push('/login')
          }
        }
      }
    }
    return config
  },
  (error) => Promise.reject(error)
)
axiosInstance.interceptors.response.use((response) => response.data)
axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
})
export default axiosInstance

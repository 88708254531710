import { API } from '../_helpers'
import TokenUtils from '../_helpers/tokenUtils'

const route = 'auth'
const login = async (body) =>
  API.post(`v1/${route}/admin-login`, body).then((data) => {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    TokenUtils.setAccessToken(data.tokens.access.token)
    TokenUtils.setRefreshToken(data.tokens.refresh.token)
  })
const logout = async () => {
  API.post(`v1/${route}/logout`, { refreshToken: TokenUtils.getRefreshToken() })
  TokenUtils.removeAccessToken()
  TokenUtils.removeRefreshToken()
}
const refreshToken = async (body, header) =>
  API.post(`v1/${route}/refresh-tokens`, body, header)
export default {
  login,
  logout,
  refreshToken,
}

import booleanInput from './booleanInput'
import dateInput from './dateInput'
import dateTimeInput from './dateTimeInput'
import emailInput from './emailInput'
import imageInput from './imageInput'
import numberInput from './numberInput'
import passwordInput from './passwordInput'
import selectInput from './selectInput'
import textAreaInput from './textAreaInput'
import textInput from './textInput'
import timeInput from './timeInput'

const getInput = (type) => {
  switch (type) {
    case 'email':
      return emailInput
    case 'password':
      return passwordInput
    case 'select':
      return selectInput
    case 'text':
      return textInput
    case 'date':
      return dateInput
    case 'datetime-local':
      return dateTimeInput
    case 'textArea':
      return textAreaInput
    case 'number':
      return numberInput
    case 'boolean':
      return booleanInput
    case 'time':
      return timeInput
    case 'image':
      return imageInput
    default:
      return textInput
  }
}
export default getInput

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useState } from 'react'

import Guard from '../Guards/guard'
import inputFactory from './inputs/inputFactory'

const AbstractDialog = (props) => {
  const [formValid, setFormValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const inputs = props.inputs || []
  const formElements = []
  inputs.forEach((inputProps, index) => {
    if (inputProps) {
      const input = inputFactory(inputProps.type)
      formElements.push(input.render(inputProps, index))
    }
  })
  const checkFormValidity = (event) => {
    const form = event.currentTarget
    let tempFormValid = true
    for (let i = inputs.length - 1; i >= 0; i--) {
      if (inputs[i] && form.elements[i]) {
        const element = form.elements[i]
        const description = inputs[i]
        const input = inputFactory(description.type)
        if (description.required && input.isEmpty(element)) {
          setErrorMessage(`${description.label} is required`)
          setFormValid(false)
          tempFormValid = false
          break
        }
        if (!input.isEmpty(element) && !input.isValid(element)) {
          setErrorMessage(input.errorMessage(description.label))
          setFormValid(false)
          tempFormValid = false
          break
        }
      }
    }
    if (tempFormValid) setFormValid(true)
  }
  const onSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    const body = {}
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i] && form.elements[i]) {
        const element = form.elements[i]
        const description = inputs[i]
        const input = inputFactory(description.type)
        if (!input.isEmpty(element))
          body[description.name] = input.getValue(element)
      }
    }
    props.onSubmit(body)
  }
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="paper"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <form
          action="/"
          method="POST"
          id="mainForm"
          onSubmit={onSubmit}
          onChange={checkFormValidity}
          onLoad={checkFormValidity}
        >
          <DialogContent>
            {props.noSubmitInfo ? (
              <Guard condition={!formValid}>
                <p style={{ color: '#ff0000' }}>{errorMessage}</p>
              </Guard>
            ) : (
              <DialogContentText style={{ color: 'black' }}>
                {props.info}
              </DialogContentText>
            )}
            {formElements.length > 0 ? formElements : props.data}
          </DialogContent>
          {props.confirm ? (
            <DialogActions>
              <Button type="submit" color="secondary" variant="contained">
                Yes
              </Button>
              <Button variant="contained" color="primary" onClick={props.close}>
                No
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button disabled={!formValid} type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          )}
        </form>
      </DialogContent>
    </Dialog>
  )
}
export default AbstractDialog

import React from 'react'

import RenderExcerpt from './renderExcert'

const Excerpt = (path) => (rowData) => {
  let paragraph = rowData[path]
  if (paragraph.length > 100) paragraph = `${paragraph.substring(0, 100)}...`
  return <RenderExcerpt paragraph={paragraph} data={rowData[path]} />
}
export default Excerpt

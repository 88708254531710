import Button from '@material-ui/core/Button'
import React, { useState } from 'react'

import Guard from '../Guards/guard'
import AbstractDialog from './abstractDialog'

const ActionButton = (props) => {
  const [open, setOpen] = useState(false)
  const onClick = () => {
    if (props.withForm) setOpen(true)
    else submit()
  }
  const submit = (args) => {
    props.onSubmit(args)
  }
  return (
    <>
      <Button variant="contained" color={props.buttonColor} onClick={onClick}>
        {props.buttonLabel}
      </Button>
      <Guard condition={props.withForm}>
        {open ? (
          <AbstractDialog
            open={open}
            close={() => setOpen(false)}
            title={props.form.title}
            info={props.form.info}
            noSubmitInfo
            inputs={props.form.inputs}
            onSubmit={(args) => {
              setOpen(false)
              submit(args)
            }}
          />
        ) : (
          <></>
        )}
      </Guard>
    </>
  )
}
export default ActionButton

import { useSnackbar } from 'notistack'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import history from '../../_helpers/history'
import { userService } from '../../_services'
import AbstractSingle from '../AbstractComponents/abstractSingle'

const Admin = () => {
  const queryFn = userService.getOne
  const deleteFn = userService.deleteOne
  const singleCollectionName = 'User'
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const { id } = params
  const { enqueueSnackbar } = useSnackbar()
  // Queries
  const { isLoading, isError, data, error } = useQuery(
    [singleQueryKey, { id }],
    () => queryFn(id),
    {
      retry: 0, // Will retry failed requests 0 times before displaying an error
    }
  )
  // Mutations
  const deleteMutation = useMutation(() => deleteFn(id), {
    onError: (err) => {
      // An error happened!
      enqueueSnackbar(
        `${singleCollectionName} could not be deleted:  ${err.response.data.message}`,
        {
          variant: 'error',
        }
      )
    },
    onSuccess: () => {
      // Invalidate and refetch
      enqueueSnackbar(`${singleCollectionName} deleted successfully`, {
        variant: 'success',
      })
      history.replace(`/admins`)
    },
  })
  const handleDelete = () => deleteMutation.mutate()
  if (isLoading) return <span className="spanText">Loading...</span>
  if (isError) {
    enqueueSnackbar(`Server Error: ${error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">Error: {error.response.data.message}</span>
    )
  }
  return (
    <AbstractSingle
      data={data}
      collectionName="Admin"
      deleteOption
      deleteFunction={() => handleDelete()}
    />
  )
}
export default Admin

import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { accountProperties } from '../../_helpers'
import history from '../../_helpers/history'
import { userService } from '../../_services'
import AbstractCollection from '../AbstractComponents/abstractCollection'
import DateTimeString from '../AbstractComponents/util/customRender/dateTimeString'

const Admins = () => {
  const queryFn = userService.getAll
  const addFn = userService.addOne
  const collectionName = 'Admins'
  const singleCollectionName = 'Admin'
  const queryKey = collectionName.toLowerCase()
  const { enqueueSnackbar } = useSnackbar()
  // Access the client
  const queryClient = useQueryClient()
  // Queries
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, '-createdAt'),
    search: withDefault(StringParam, ''),
  })
  const { isLoading, isError, data, error } = useQuery(
    [queryKey, { page: query.page, sort: query.sort, search: query.search }],
    () => queryFn(query.page, query.sort, query.search)
  )
  // Mutations
  const mutation = useMutation((body) => addFn(body), {
    onError: (err) => {
      // An error happened!
      enqueueSnackbar(
        `${singleCollectionName} could not be added:  ${err.response.data.message}`,
        {
          variant: 'error',
        }
      )
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(queryKey)
      enqueueSnackbar(`${singleCollectionName} added successfully`, {
        variant: 'success',
      })
    },
  })
  const handleAdd = (args) => mutation.mutate({ ...args, role: 'admin' })
  if (isLoading) return <span className="spanText">Loading...</span>
  if (isError) {
    enqueueSnackbar(`Server Error: ${error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">Error: {error.response.data.message}</span>
    )
  }
  return (
    <AbstractCollection
      columns={[
        {
          title: 'Name',
          field: 'name',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Email',
          field: 'email',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Role',
          field: 'role',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Creation Date',
          field: 'createdAt',
          render: DateTimeString(['createdAt']),
          customFilterAndSearch: () => true,
        },
      ]}
      mainActions={[
        {
          buttonLabel: `Add ${singleCollectionName}`,
          buttonColor: 'primary',
          withReload: true,
          onSubmit: (args) => handleAdd(args),
          withForm: true,
          form: {
            title: `Add ${singleCollectionName}`,
            inputs: [
              {
                name: 'email',
                label: 'Email',
                type: 'email',
                required: true,
              },
              {
                name: 'password',
                label: 'Password',
                type: 'password',
                required: true,
              },
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
            ],
          },
        },
      ]}
      actions={[
        {
          onClick: (event, row) => {
            if (row.email === accountProperties().sub)
              history.push(`/profile/${row.email}`)
            else history.push(`/admin/${row.email}`)
          },
          icon: OpenInNewIcon,
        },
      ]}
      title={collectionName}
      isLoading={isLoading}
      isError={isError}
      documents={data.documents}
      pages={data.pages}
      error={error}
      query={query}
      setQuery={setQuery}
    />
  )
}
export default Admins

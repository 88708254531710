import { useSnackbar } from 'notistack'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import history from '../../_helpers/history'
import { appointmentService } from '../../_services'
import AbstractSingle from '../AbstractComponents/abstractSingle'
import ObjectHelper from '../AbstractComponents/util/objectsHelper'

const Appointment = () => {
  const queryFn = appointmentService.getOne
  const updateFn = appointmentService.editOne
  const deleteFn = appointmentService.deleteOne
  const collectionName = 'Appointments'
  const singleCollectionName = 'Appointment'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const { id } = params
  const { enqueueSnackbar } = useSnackbar()
  // Access the client
  const queryClient = useQueryClient()
  // Queries
  const { isLoading, isError, data, error } = useQuery(
    [singleQueryKey, { id }],
    () => queryFn(id)
  )
  // Mutations
  const updateMutation = useMutation((Data) => updateFn(id, Data), {
    onError: (err) => {
      // An error happened!
      enqueueSnackbar(
        `${singleCollectionName} could not be edited: ${err.response.data.message}`,
        {
          variant: 'error',
        }
      )
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(queryKey)
      queryClient.invalidateQueries(singleQueryKey, id)
      enqueueSnackbar(`${singleCollectionName} edited successfully`, {
        variant: 'success',
      })
    },
  })
  const deleteMutation = useMutation(() => deleteFn(id), {
    onError: (err) => {
      // An error happened!
      enqueueSnackbar(
        `${singleCollectionName} could not be deleted: ${err.response.data.message}`,
        {
          variant: 'error',
        }
      )
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(queryKey)
      queryClient.invalidateQueries(singleQueryKey, id)
      enqueueSnackbar(`${singleCollectionName} deleted successfully`, {
        variant: 'success',
      })
      history.replace(`/appointments`)
    },
  })
  const handleUpdate = (args) => {
    const body = ObjectHelper.compare(data, args)
    if (Object.keys(body).length) updateMutation.mutate(body)
  }
  const handleDelete = () => deleteMutation.mutate()
  if (isLoading) return <span className="spanText">Loading...</span>
  if (isError) {
    enqueueSnackbar(`Server Error: ${error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">Error: {error.response.data.message}</span>
    )
  }
  const updateForm = [
    {
      name: 'reason',
      label: 'Reason',
      type: 'text',
      value: data.reason,
      required: false,
    },
    {
      name: 'message',
      label: 'Message',
      type: 'text',
      value: data.message,
      required: false,
    },
    {
      name: 'dateTime',
      label: 'Date & Time',
      type: 'datetime-local',
      value: data.dateTime,
      required: false,
    },
  ]
  return (
    <AbstractSingle
      data={data}
      collectionName={singleCollectionName}
      updateOption
      updateForm={updateForm}
      updateFunction={(args) => handleUpdate(args)}
      deleteOption
      deleteFunction={() => handleDelete()}
    />
  )
}
export default Appointment

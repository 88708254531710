import 'date-fns'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const input = {
  isValid: (element) => element.value.length !== 0,
  isEmpty: (element) => element.value.length === 0,
  errorMessage: (label) => `${label} is invalid`,
  render: (props, index) => (
    <div style={{ margin: '10px 0' }}>
      <Typography variant="caption" display="block" gutterBottom>
        {props.label + (props.required ? '*' : '')}
      </Typography>
      <TextField
        name="upload-photo"
        type="file"
        accept="image/png, image/jpeg"
        inputProps={{ multiple: true }}
        key={index}
        autoFocus={index === 0}
        style={{ width: '100%' }}
      />
    </div>
  ),
  getValue: (element) => element.files,
}
export default input

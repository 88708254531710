import decode from 'jwt-decode'

import history from './history'

const isTokenExpired = (token) => {
  try {
    if (token) {
      const decoded = decode(token)
      // Checking if token is expired.
      if (decoded.exp < Date.now() / 1000) return true
      return false
    }
  } catch (err) {
    history.push('/login')
    return false
  }
}
export default isTokenExpired

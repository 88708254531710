import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import {
  brandsFields,
  categoriesFields,
  specificationsFields,
} from '../../_helpers/constants'
import { itemService } from '../../_services'
import AbstractCollection from '../AbstractComponents/abstractCollection'
import DateTimeString from '../AbstractComponents/util/customRender/dateTimeString'
import objectsHelper from '../AbstractComponents/util/objectsHelper'

const Items = () => {
  const queryFn = itemService.getAll
  const addFn = itemService.createOne
  const collectionName = 'Items'
  const singleCollectionName = 'Item'
  const queryKey = collectionName.toLowerCase()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  // Access the client
  const queryClient = useQueryClient()
  // Queries
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sort: withDefault(StringParam, '-createdAt'),
    search: withDefault(StringParam, ''),
  })
  const { isLoading, isError, data, error } = useQuery(
    [queryKey, { page: query.page, sort: query.sort, search: query.search }],
    () => queryFn(query.page, query.sort, query.search)
  )
  // Mutations
  const mutation = useMutation((body) => addFn(body), {
    onError: (err) => {
      // An error happened!
      enqueueSnackbar(
        `${singleCollectionName} could not be added: ${err.response.data.message}`,
        {
          variant: 'error',
        }
      )
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(queryKey)
      enqueueSnackbar(`${singleCollectionName} added successfully`, {
        variant: 'success',
      })
    },
  })
  const handleAdd = (args) => {
    const { media } = args
    delete args.media
    const formData = objectsHelper.getFormData(args)
    Object.keys(media).forEach((key) => formData.append('media', media[key]))
    mutation.mutate(formData)
  }
  if (isLoading) return <span className="spanText">Loading...</span>
  if (isError) {
    enqueueSnackbar(`Server Error: ${error.response.data.message}`, {
      variant: 'error',
    })
    return (
      <span className="spanText">Error: {error.response.data.message}</span>
    )
  }
  return (
    <AbstractCollection
      columns={[
        {
          title: 'Name',
          field: 'name',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Category',
          field: 'category',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Brand',
          field: 'brand',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Price',
          field: 'price',
          type: 'currency',
          currencySetting: {
            currencyCode: 'EGP',
          },
          customFilterAndSearch: () => true,
        },
        {
          title: 'Sale Price',
          field: 'salePrice',
          type: 'currency',
          currencySetting: {
            currencyCode: 'EGP',
          },
          customFilterAndSearch: () => true,
        },
        {
          title: 'Featured',
          field: 'featured',
          customFilterAndSearch: () => true,
        },
        {
          title: 'On Sale',
          field: 'onSale',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Out Of Stock',
          field: 'outOfStock',
          customFilterAndSearch: () => true,
        },
        {
          title: 'Creation Date',
          field: 'createdAt',
          render: DateTimeString(['createdAt']),
          customFilterAndSearch: () => true,
        },
      ]}
      mainActions={[
        {
          buttonLabel: `Add ${singleCollectionName}`,
          buttonColor: 'primary',
          withReload: true,
          onSubmit: (args) => handleAdd(args),
          withForm: true,
          form: {
            title: `Add ${singleCollectionName}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'category',
                label: 'Category',
                type: 'select',
                options: categoriesFields,
                required: true,
              },
              {
                name: 'brand',
                label: 'Brand',
                type: 'select',
                options: brandsFields,
                required: true,
              },
              {
                name: 'description',
                label: 'Description',
                type: 'textArea',
                required: false,
              },
              {
                name: 'price',
                label: 'Price',
                type: 'number',
                required: true,
              },
              {
                name: 'salePrice',
                label: 'Sale Price',
                type: 'number',
                required: false,
              },
              {
                name: 'featured',
                label: 'Featured',
                type: 'boolean',
                required: false,
              },
              {
                name: 'onSale',
                label: 'On Sale',
                type: 'boolean',
                required: false,
              },
              {
                name: 'outOfStock',
                label: 'Out Of Stock',
                type: 'boolean',
                required: false,
              },
              {
                name: 'media',
                label: 'Media',
                type: 'image',
                required: true,
              },
              ...specificationsFields(),
            ],
          },
        },
      ]}
      actions={[
        {
          onClick: (event, row) => history.push(`/item/${row.slug}`),
          icon: OpenInNewIcon,
        },
      ]}
      title={collectionName}
      isLoading={isLoading}
      isError={isError}
      documents={data.documents}
      pages={data.pages}
      error={error}
      query={query}
      setQuery={setQuery}
    />
  )
}
export default Items
